import JSBI from 'jsbi'

// exports for external consumption
export type BigintIsh = JSBI | bigint | string

// modified by haruna
export enum ChainId {
  MAINNET = 100,
  TESTNET = 77
}

export enum TradeType {
  EXACT_INPUT,
  EXACT_OUTPUT
}

export enum Rounding {
  ROUND_DOWN,
  ROUND_HALF_UP,
  ROUND_UP
}

// modified by haruna
export const FACTORY_ADDRESS = '0x0460584db2D68986f8E2D6eA49A79ee1FA81f38A'
// modified by haruna
export const INIT_CODE_HASH = '0xc7f2fa82b25b1021afa5dfbafd114f40c6ed84cc6946c0ca0e5a90561782f50a'

export const MINIMUM_LIQUIDITY = JSBI.BigInt(1000)

// exports for internal consumption
export const ZERO = JSBI.BigInt(0)
export const ONE = JSBI.BigInt(1)
export const TWO = JSBI.BigInt(2)
export const THREE = JSBI.BigInt(3)
export const FIVE = JSBI.BigInt(5)
export const TEN = JSBI.BigInt(10)
export const _100 = JSBI.BigInt(100)
export const FEES_NUMERATOR = JSBI.BigInt(998)
export const FEES_DENOMINATOR = JSBI.BigInt(1000)

export enum SolidityType {
  uint8 = 'uint8',
  uint256 = 'uint256'
}

export const SOLIDITY_TYPE_MAXIMA = {
  [SolidityType.uint8]: JSBI.BigInt('0xff'),
  [SolidityType.uint256]: JSBI.BigInt('0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff')
}
